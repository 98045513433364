//
// Root
//
.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: #c8c0fb7d !important;
    color: var(--bs-table-hover-color);
}
.bg-light-info.p-8.rounded .text-light {
    font-weight: 700;
}
.bg-light-info {
    background-color: #00c796 !important;
}
html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}
.bg-logo-bicho {
    background: #fff !important;
}
.alice-carousel__wrapper {
    height: 75px !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: #c8c0fb21;
    color: var(--bs-table-striped-color);
}
.alice-carousel {
    position: relative;
    width: -webkit-fill-available;
    margin: 0px 20px;
    direction: ltr;
}
.app-header-secondary .app-header-slider {
    border-top: var(--bs-header-secondary-top-border-color);
    border-bottom: var(--bs-header-secondary-bottom-border-color);
}
@media (min-width: 1400px){
.ms-xxl-n18 {
    margin-left: -4.5rem !important;
}}
.title-capitalice {
    text-transform: capitalize !important;
}
.margin-bottom {
    margin-bottom: 20px !important;
}
.btn.btn-active-light-primary:hover:not(.btn-active) i{
    color: #3615bd;
}
.btn.btn-active-light-primary:hover:not(.btn-active){
    background-color: #f1f1f4 !important;
}
.react-select .filter__control {
    background-color: transparent;
    width: 150px;
    border-color: transparent;
}
.react-select .filter__indicator-separator{
    background-color: transparent;
    width: 0px;
}
.filter__control {
    border-radius: 5px !important;
}
.color-whats {
    color: #25D366;
}
span.btn-icon i {
    margin-right: 5px;
}
html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-sans-serif;

    // Tablet mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }    
}

body {
    display: flex;
    flex-direction: column;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

canvas {
    user-select: none;
}

// Angular integration
router-outlet {
    display: none;
}