.containerTable {
  background: #ffffff;
  position: relative;
}

.course-button {
  height: calc(1.4em + 0.5rem + 1px) !important;
  width: calc(1.4em + 0.5rem + 1px) !important;
  padding: 5px !important;
}
.table-0-padding thead {
  display: none;
}
.table-0-padding td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
span.fs-5.fw-bold.text-gray-900.text-capitalize {
  text-transform: lowercase !important;
}
span.fs-5.fw-bold.text-gray-900.text-capitalize:first-letter {
  text-transform:uppercase !important;
}
.card-user {
  padding: 0.75rem 1.5rem;
}
.card-user:hover {
  background-color: #f5f5f59e !important;
}
.card.table-0-padding .d-flex.justify-content-between.pb-2 {
  width: 100% !important;
}
.card.table-0-padding .d-flex.align-items-center.position-relative.my-1 {
  width: 100% !important;
}
.btn.btn-primary.btn-xs {
  border-radius: 3.25rem !important;
}
.switch.btn.on.btn-primary.btn-xs.form-switch-tab {
  border: 3px solid #3615af !important;
}
.switch.btn.off.btn-light.btn-xs.form-switch-tab {
  border: 3px solid #00c796 !important;
}
.css-tab-bu {
  position: relative;
  top: 60px;
  z-index: 90;
  padding: 0 2.25rem;
}
.flex-column.pt-6{
  width: 100% !important;
  text-align: center;
}
span.switch-off.btn.btn-light.btn-xs {
  padding: 10px 6px !important;
  background: #00c796;
  color:#fff;
  text-align: right !important;
}
span.switch-off.btn.btn-light.btn-xs:hover {

  background:#00c796 !important;
 
}
.btn.btn-light.btn-xs {
  border-radius: 3.25rem !important;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
}
span.switch-handle.btn.btn-lightbtn-xs {
    border-radius: 3.25rem !important;
}
.btn.btn-lightbtn-xs:hover {
  color: #fff;
  background: #fff;
}
span.switch-on.btn.btn-primary.btn-xs {
  /* right: 10px !important; */
  padding: 10px 10px !important;
  text-align: left !important;}
.react-tooltip {
  background: #d2cbff !important;
  color: #3615af !important;
}
.fw-mormal {
  font-weight: 400;
}
.table-0-padding input {
  width: 100% !important;
}
.card.table-0-padding .table-responsive {
  height: 200px;
}
.card .card-header{
  min-height: 50px;
}
