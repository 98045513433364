.page-item.disabled .page-link svg path {
  fill: #b5b5c3;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}
td.align-items-center {
  vertical-align: middle;
}
span.text-left {
  text-align: left !important;
}
.column-right {
  text-align: right;
}

.column-left {
  text-align: left;
}
span.text-right {
  text-align: right !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.page-item.disabled .page-link svg path {
  fill: #b5b5c3;
}
.page-item.next .page-link svg path {
  fill: #5e6278;
}
.table td {
  text-transform: lowercase !important;
}
.text-capitalize {
  text-transform: initial !important;
}
.table td:first-letter {
  text-transform: uppercase;
}
.badge {
  text-transform: none !important;
}
.page-item .page-link .next,
.page-item .page-link .previous {
  display: block;
  height: 0.875 rem;
  width: 0.875 rem;
}
.table.gy-6 th,
.table.gy-6 td {
  padding-top: 0.5rem;
  padding-right: 18px;
  padding-bottom: 0.5rem;
}
.page-item .page-link i {
  font-size: 0.85rem;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link,
.page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link {
  background-color: #fff;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.475 rem;
  border-bottom-right-radius: 0.475 rem;
}
.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-link svg {
  width: 15px;
  height: 15px;
}
.page-link {
  position: relative;
  display: block;
  fill: #5e6278;
  background-color: transparent;
  border: 0 solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.scrolltop svg rect {
  transition: fill 0.3s ease;
  fill: #fff;
}
.scrolltop svg path {
  transition: fill 0.3s ease;
  fill: #fff;
}
