//
// Main
//

.bg-light-verde{
    background-color: #d9fdd3;
	border-radius: 20px 20px 0 20px !important;
	box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
}
aside.EmojiPickerReact.epr-main {
    bottom: 465px;
    left: 608px;
}
.bg-success.card-cabe {
    padding: 3px;
    border-radius: 6px;
    padding-right: 10px;
	padding-left: 10px;
}
li.alice-carousel__stage-item {
    padding: 10px 20px;
}
.loadin-new{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-m {
    min-height: 320px;
    height: 320px;
}
.mayus{
	text-transform: uppercase !important;
}
label.fs-4.fw-bold.text-gray-800 {
    margin-bottom: 10px;
}
.emoji-bg{
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url("https://unpkg.com/emoji-datasource-apple@4.0.4/img/apple/sheets-256/64.png");
    background-size: 5200%;
    background-position: 58.8235% 54.902%;
}
.radio-image {
    user-select: auto;
    display: inline-block;
    order: 0;
    line-height: inherit;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
	padding-right: 20px;
}


ol, ul {
    padding-left: 1rem !important;
}
.aside-menu .menu .menu-item .menu-link .menu-icon i {
    font-size: 18px;
}
.react-tooltip { background: #f2f2f2 !important; color: #000000 !important; font-size: 12px; }
aside.EmojiPickerReact.epr-main {
	position: absolute !important;
    z-index: 1;
    margin-top: 5px;
}
.bg-light-gris{
	border-radius: 20px 20px 20px 0 !important;
    background-color: #fff;
	box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
}
.aside-menu .menu .menu-item .menu-link .menu-icon .svg-icon path {
    fill: #3615af;
}
// Body
body {
	background-color: #d2cbff;
}
.nav-line-tabs .nav-item .nav-link.active{
    transition: color 0.2s ease;
    color: var(--kt-text-primary) !important;
}
// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease;
		padding-top: get($header-config, height, desktop);
		padding-left: get($aside-config, width);

		// Aside default enabled, aside fixed and aside minimize modes
		[data-kt-aside-minimize="on"] & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: 0;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Containers
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: get($header-config, height, tablet-and-mobile);
		}
	}
}
