//
// Layout Config
//

// Root font Sizes
$root-font-size:                                    13px; // Root font size for desktop mode
$root-font-size-lg:                             	13px; // Root font size for tablet mode
$root-font-size-md:                             	12px; // Root font size for mobile mode

// Page bg
$page-bg: 											#f1f1f4 !default;
$page-bg-dark: 										#121212 !default;

// Content border radius
$content-border-radius:								1.5rem !default;

// Content Spacing
$content-spacing: (
	desktop: 30px, // Padding for desktop mode
	tablet-and-mobile: 15px // Padding for tablet and mobile modes
) !default;

// Header
$header-config: (
	height: (
		desktop: 74px,
		tablet-and-mobile: 55px
	),
	z-index: 100,
	bg-color: $white,
	bg-color-dark: #D2CBFF,
	border-bottom: 0,
	border-bottom-dark: 1px solid #3615af,
	box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05),
	box-shadow-dark: none	
) !default;

// Aside
$aside-config: (
	width: 265px, // Aside width for desktop mode
	z-index: 101, // Aside's z-index property
	transition-speed: 0.3s, // transition speed
	padding-x: 25px,
	footer-padding-x: 15px,
	menu-padding-x: 10px,
	menu-indention: 0.75rem,
	bg-color: #3615af,
	scrollbar-color: #ffff,
	scrollbar-hover-color: lighten(#fff, 2%)	
) !default;

// Aside
$footer-config: (	
	bg-color: $white,
	bg-color-dark: #1B1B28,
) !default;
